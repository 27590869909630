import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

const VlogKamuHebatPage = ({ data }) => {
  const videoData = data.allYoutubeVideo.edges.map(({ node }) => {
    return (
      <div key={`${node.videoId}`} className="col-12 col-md-6 col-lg-3 mt-5">
        <Link
          className="text-decoration-none text-dark"
          to={`/vlog-kamu-hebat/${node.title
            .toLowerCase()
            .replace(/ /g, "-")
            .replace(/---/g, "-")
            .replace(/-&-/g, "-")
            .replace(/,/g, "")
            .replace(".", "")
            .replace(/"/g, "")
            .replace(/!/g, "")
            .replace(/\?\?/g, "")
            .replace("stress?", "stress")
            .replace("bercerai?", "bercerai")
            .replace("sorga-?", "sorga")
            .replace(/'/g, "")
            .replace(/:/g, "")
            .replace(/&/g, "-and-")
            .replace("-?", "")
            .replace(/#/g, "")
            .replace(/\s+/g, "-")
            .replace(/[^\w\-]+/g, "")
            .replace(/\-\-+/g, "-")
            .replace(/^-+/, "")
            .replace(/-+$/, "")}`}
        >
          <div
            className="position-relative w-100"
            style={{ paddingTop: "56.26%" }}
          >
            <Img
              className="position-absolute w-100 rounded shadow"
              style={{
                height: "100%",
                top: "0",
                left: "0",
              }}
              fluid={node.localThumbnail.childImageSharp.fluid}
            />
          </div>
          <div className="my-3" />
          <h6>{node.title}</h6>
        </Link>
      </div>
    )
  })
  return (
    <React.Fragment>
      <SEO title="60 Seconds with Ps.Gilbert Lumoindong" />
      <Layout>
        <div className="container">
          <h1 className="text-center">
            Temukan Vlog #KamuHebat Bersama Ps.Gilbert Lumoindong
          </h1>
          <div className="my-5" />
          <div className="row">{videoData}</div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default VlogKamuHebatPage

export const query = graphql`
  {
    allYoutubeVideo(filter: { channelTitle: { eq: "Gilbert Lumoindong" } }) {
      edges {
        node {
          title
          localThumbnail {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  }
`
